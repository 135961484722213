.ant-layout.login-bg {
  height: 100vh !important;
  background: url(/public/assets/img/bg.jpg) no-repeat;
  background-size: cover;
  background-position: 22% 10%;
  height: auto;
  position: relative;
  text-align: center;
  min-height: 746px;
}
.login-bg .ant-card-body {
  padding: 0;
}

.login-bg .username-input::after {
  content: "";
  width: 25px;
  height: 25px;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 10px;
  background-image: url("/public/images/icon/login.svg");
}

.login-bg .password-input::after {
  content: "";
  width: 25px;
  height: 25px;
  background-size: cover;
  position: absolute;
  top: 5px;
  left: 10px;
  background-image: url("/public/images/icon/password.svg");
}
.login-bg .ant-form-item-control-input-content,
.login-bg .password-box {
  position: relative;
}
.login-bg .password-box .isShowPass {
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  top: 9px;
  right: 5%;
  cursor: pointer;
  display: none;
}
.login-bg .password-box .isShowPass.show {
  display: block;
}
.login-bg .text-login {
  margin-top: 10px;
}
.login-bg .btn-submit {
  margin-top: 0;
  width: 100%;
}
.login-bg .forget {
  font-weight: bold;
}
.login-bg .card-body {
  background: rgb(0 0 0 / 85%);
  right: -50%;
}
.login-bg .img-header {
  margin-left: 25%;
  margin-top: 35px;
  width: 55%;
}
.login-bg .ant-btn-lg {
  border-radius: 9px;
}
.login-bg .ant-input {
  border-radius: 5px;
}
.login-bg .login-line {
  border-bottom: 2px solid #f4f3f6;
}
.login-bg .login-row {
  margin: 0;
}
.login-bg .login-row .login-text {
  color: white;
  font-weight: bold;
  position: relative;
  top: 9px;
}
.login-bg .login-h1 {
  font-size: 14px;
  line-height: 15px;
  color: #fff;
}
