.map-monitoring-modal {
  height: auto !important;
  max-width: 80%;
}

.map-boundary-modal .search-modal {
  color: white;
  background-color: #428bca;
  margin: -1px -1px 0 -1px;
  padding-bottom: 15px;
}

.map-monitoring-modal .flexible-modal-drag-area {
  background-color: transparent;
}

.map-monitoring-modal .flexible-modal-drag-area-left,
.map-monitoring-modal .flexible-modal-drag-area-bottom,
.map-monitoring-modal .flexible-modal-drag-area-right {
  display: none;
}
.map-monitoring-modal .form-group .search-work {
  margin-top: -11px;
}

.map-monitoring-modal .ant-tabs .ant-tabs-nav {
  margin: 0;
  padding: 0 15px;
}

.map-monitoring-modal .ant-tabs .ant-tabs-content-holder {
  padding: 15px;
}

.map-monitoring-modal .btn-save {
  margin: 0 auto 15px auto;
}

.map-monitoring-modal .row.track-individual-people {
  margin-left: 0;
}
.map-monitoring-modal .error {
  border-color: red;
  color: red;
}
.map-monitoring-modal .textalert {
  color: red;
}
.map-monitoring-modal .btn-save {
  width: 200px;
}
