.map-filter-modal {
  width: 350px;
  background-color: white;
  position: absolute;
  top: 200px;
  right: 10px;
}
.map-filter-modal .ant-modal-content {
  padding: 0;
}
.map-filter-modal .accordion-body {
  height: calc(100% - 52px);
  overflow-y: scroll;
}
.map-filter-modal .accordion-body .details {
  font-size: 20px;
  color: black;
}

.map-filter-modal .expand-modal,
.map-filter-modal .close-modal {
  position: absolute;
  z-index: 5;
  cursor: pointer;
}

.map-filter-modal .expand-modal {
  width: 20px !important;
  height: 10px !important;
  top: 17px;
  right: 45px;
}

.map-filter-modal .close-modal {
  width: 25px !important;
  height: 25px !important;
  top: 10px;
  right: 10px;
}
.map-filter-modal .accordion-header {
  padding: 16px 20px;
  background-color: #428bca;
  color: white;
}
.map-filter-modal ul {
  margin-left: 20px;
  margin-bottom: 10px;
}
.map-filter-modal ul li {
  margin-right: 0;
  display: block;
}
.map-filter-modal ul li label {
  margin-bottom: 10px;
}

.map-filter-modal ul li span {
  padding-right: 0;
}
.map-filter-modal ul li .ant-checkbox-inner {
  background-color: #428bca;
  border-color: #428bca;
}
