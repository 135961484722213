.map-boundary {
  position: relative;
}
.map-boundary .map {
  width: 100%;
  height: auto;
}
.collapseTwoShowBoundary {
  height: 230px !important;
}
.map-boundary .map-point {
  position: relative;
  overflow: hidden;
}
.fontmapboundary {
  font-size: "15px" !important;
}
.map-boundary .accordion-header {
  padding: 10px 20px;
}
.map-boundary .map-point .search {
  width: 32px;
  height: 32px;
  position: absolute;
  z-index: 20;
  left: 15px;
  top: 90px;
}
.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: rgba(55, 55, 55, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.map-boundary .size-map {
  padding: 0;
  margin-top: 60px;
  margin-bottom: 20px;
}
.map-boundary .text-header {
  position: absolute;
  left: 93px;
  top: 10px;
}
.map-boundary .sketch-picker {
  position: absolute;
  z-index: 20;
}
.map-boundary .modal-color {
  padding: 0;
}
.map-boundary #remark {
  width: calc(100% + 20px);
  border-radius: 5px;
  border-color: #ced4da;
}
.map-boundary .color-box {
  border: 1px solid black;
  cursor: pointer;
}
.map-boundary .color-box-container {
  margin-bottom: 10px;
}
.map-boundary .color-box-container .modal-color {
  position: absolute;
  top: 0px;
  left: 70px;
}
.map-boundary .color-box-container .modal-color .react-colorful {
  z-index: 20;
}
.map-boundary .color-box-container .modal-color .cover {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.map-boundary .body,
.map-boundary .location-detail {
  padding: 0 40px;
}
.map-boundary .ant-pagination {
  padding-right: 40px;
}
.map-boundary .filter-button {
  width: 33px;
  height: 33px;
  position: absolute;
  right: 18px;
  top: 18px;
  display: none;
  z-index: 20;
}
.map-boundary .button-search {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 140px;
  right: 8px;
  background-color: #428bca;
}
.map-boundary .search {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 15px;
  top: 90px;
}

.map-boundary .show-restrict-list {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 200px;
  left: 15px;
  background-color: #428bca;
}
.map-boundary .toggle {
  position: absolute;
  width: 33px;
  height: 33px;
  top: 157px;
  left: 15px;
  background-color: #428bca;
}
.map-boundary .ship-ptt {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 15px;
  top: 190px;
}
.map-boundary .resetMap {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 15px;
  top: 330px;
}
.map-boundary #LocationName.error {
  border-color: red;
}

.map-boundary .esri-ui-bottom-left {
  bottom: 70px;
  left: -100px;
}

.map-boundary-basemap .color-box {
  background: transparent url("/public/images/icon/X-ray.svg") no-repeat center
    center/40px 40px;
}

.map-boundary-basemap .color-box.chemical {
  background: transparent url("/public/images/icon/chemical.svg") no-repeat
    center center/40px 40px;
}

.map-boundary-basemap .color-box.maintenance {
  background: transparent url("/public/images/icon/maintenance.svg") no-repeat
    center center/40px 40px;
}

.map-boundary-basemap .color-box.forbiddenPass {
  background: transparent url("/public/images/icon/forbiddenPass.svg") no-repeat
    center center/40px 40px;
}

.map-boundary-basemap .color-box.obstruction {
  background: transparent url("/public/images/icon/obstruction.svg") no-repeat
    center center/40px 40px;
}
