.map-measurement {
  position: absolute;
  top: 20px;
  right: 14px;
  width: 102px;
  display: flex;
}
.map-measurement .esri-widget--button {
  border: none;
  background-color: white;
  color: black;
  width: 33px;
  height: 33px;
  margin-right: 1px;
}
