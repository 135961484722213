.create-role .permission-row .form-check,
.create-role .permission-row .form-check .ant-checkbox-wrapper {
  padding: 0;
}

.create-role
  .permission-row
  .form-check
  .ant-checkbox-wrapper
  .ant-checkbox-inner {
  border: 1px solid #262323 !important;
}

.create-role .status-row .form-check .form-check-input {
  width: 20px;
  height: 20px;
}

.create-role .status-row .form-check .form-check-label {
  position: relative;
  top: 3px;
}
.create-role #role-name.error {
  border-color: red;
}
