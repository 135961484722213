.dashboard .collapseTwoShowContent {
  height: 312px !important;
  width: calc(100% + 10px);
  overflow-y: scroll;
}
@media (max-width: 800px) {
  .dashboard .collapseTwoShowContent {
    height: 624px !important;
  }
}

.dashboard .collapseThreeShowContent {
  height: 344px !important;
}

.dashboard .collapseFourShowContent {
  height: 730px !important;
}

.dashboard .collapseFiveShowContent {
  height: 344px !important;
}

.dashboard .overflow.notification {
  height: 0;
}

.dashboard .overflow.notification.is-notification {
  height: 240px;
  border-radius: 0 0 8px 8px;
}

.dashboard .overflow.notification .collapseNotification {
  height: 300px !important;
  width: calc(100% + 10px);
  overflow: scroll;
}

.dashboard .permit-bar .txt p {
  line-height: 21px;
}

.dashboard .permit-bar .txt p {
  line-height: 21px;
}

.dashboard .permit-bar .txt {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #707070;
}

.dashboard .grid-story3 {
  margin-top: 20px;
}

.dashboard .grid-story3 .card-body {
  margin-top: 0;
}
.dashboard .ant-progress-inner .ant-progress-text {
  font-size: 16px;
}

.dashboard .header-topic {
  margin-bottom: 0;
  padding-bottom: 0;
}

.dashboard .header-topic h1 {
  font-size: 16px;
}
.dashboard .card-body {
  margin-top: 10px;
}
.dashboard .no-padding {
  padding: 0;
}

.dashboard .graph-grid-container {
  display: grid;
  grid-template-columns: 33.4% 25% calc(41.6% - 20px);
  grid-gap: 10px;
}

.dashboard .graph-grid-container .doughnut-graph-grid-container {
  display: grid;
  grid-template-columns: 50% 50%;
}

.dashboard .doughnut-graph {
  width: 300px;
  height: 200px;
  position: absolute;
  left: -40px;
}
.dashboard .highcharts-container {
  margin: 0 auto;
}

.dashboard .chart-active .graph-container {
  overflow: hidden;
  position: relative;
  min-width: 216px;
  min-height: 250px;
}

.dashboard .chart-active .graph-container h6 {
  margin-top: 10px;
  margin-left: 20px;
}
@media (max-width: 1530px) {
  .dashboard .chart-active .graph-container h6 {
    font-size: 14px;
  }
}

@media (max-width: 1300px) {
  .dashboard .graph-grid-container {
    grid-template-columns: calc(50% - 5px) calc(50% - 5px);
  }
  .dashboard .doughnut-graph {
    left: -50px;
  }
  .dashboard .history-chart-box {
    margin: 0;
    grid-column: 1 / span 2;
  }
}

@media (max-width: 850px) {
  .dashboard .graph-grid-container {
    grid-template-columns: 100%;
  }
  .dashboard .doughnut-graph {
    left: 0;
  }
  .dashboard .history-chart-box {
    margin: 0;
    grid-column: auto;
  }
}

@media (max-width: 570px) {
  .dashboard .doughnut-graph {
    left: -50px;
  }
}
