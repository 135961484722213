.report-table .ant-table {
  padding: 0;
  border-radius: 8px;
}

.report-table .ant-table .ant-table-cell:last-child {
  text-align: center;
}
.report-table.loading {
  display: flex;
  background-color: white;
  clear: both;
}
.report-table.loading img {
  margin: auto;
}

.report-table .ant-table-container table th.ant-table-cell:first-child {
  border-top-left-radius: 8px;
}
.report-table .ant-table-container table th.ant-table-cell:last-child {
  border-top-right-radius: 8px;
}

.report-table .ant-table-container table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 8px;
}
.report-table .ant-table-container table tr:last-child td:last-child {
  border-bottom-right-radius: 8px;
}

.report-table .ant-pagination {
  background-color: transparent;
  --bs-table-accent-bg: none;
}
