#footer {
  z-index: 20;
  display: flex;
  padding: 10px 20px;
  height: auto;
}
#footer .company,
#footer .contact {
  width: 50%;
}
#footer .company p {
  text-align: left;
}
#footer .contact p {
  text-align: right;
}
