.map-monitoring .gridMapmonitoring {
  grid-template-columns: repeat(7, 1fr);
}
.map-monitoring .accordion-button::after {
  display: none;
}
.map-monitoring .accordion-item {
  border: 1px solid #3c4650;
  border-radius: 0;
}
.map-monitoring .accordion-item .grid-story6 {
  gap: 0;
  grid-template-columns: 5% 21% 20% 15% 12.5% 12.5% 14%;
}
.map-monitoring .search-modal {
  padding-left: 15px;
  padding-top: 5px;
}
.map-monitoring .size-map {
  padding: 0;
  margin-top: 40px;
  margin-bottom: 20px;
}
.map-monitoring .text-header {
  margin-top: 30px;
  padding: 0 15px;
}
.map-monitoring .map {
  position: relative;
}
.map-monitoring .worker {
  background-color: #f0f2f5;
  position: absolute;
  top: 18px;
  left: 57px;
  margin: 0;
  padding: 5px;
  border-radius: 6px;
  border: 3px solid #428bca;
}
.map-monitoring .worker img {
  width: 40px;
  height: 40px;
}

.map-monitoring .filter-button {
  width: 33px;
  height: 33px;
  position: absolute;
  right: 18px;
  top: 18px;
}
.map-monitoring .close-filter-result-button {
  width: 33px;
  height: 33px;
  position: absolute;
  right: 18px;
  top: 60px;
}
.map-monitoring .card-body {
  padding: 0 !important;
  border-radius: 0;
}

.map-monitoring .filter-button.active {
  display: block;
}

.map-monitoring .zoom-level {
  position: absolute;
  background-color: white;
  color: black;
  font-size: 20px;
  right: 50px;
  bottom: 110px;
  padding: 5px 10px;
}
.map-monitoring .past-map {
  position: absolute;
  background-color: white;
  color: black;
  font-size: 20px;
  left: 942px;
  top: 30px;
  padding: 5px 10px;
}
.map-monitoring .esri-ui-bottom-left {
  bottom: 70px;
  left: 45px;
}

.map-monitoring .focus-emp {
  height: calc(100vh - 20px);
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.map-monitoring .focus-emp.focus {
  display: block;
}
.map-monitoring .focus-emp .icon {
  width: 36px;
  height: 36px;
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 18px);
}
