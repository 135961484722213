.report .ant-pagination {
  margin-bottom: 50px;
  margin-top: 30px;
}

.report .form .btn-save {
  margin-top: 17px;
}

.report .download-all-box {
  display: flex;
  justify-content: flex-end;
}

.report .download-all-box .btn {
  margin: 0;
  margin-right: 180px;
}
.report .btn-save {
  margin-top: 0px;
  margin-bottom: -9px;
  margin-right: 0;
}
.report .card-body.table-responsive {
  min-height: 300px;
}
