.ant-layout.forget-password {
  height: 100vh !important;
  background-image: url(/public/images/bg-login.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  max-width: none;
  margin: 0;
  position: static;
  padding: 0;
  min-height: 746px;
}
.forget-password .txtHeader {
  text-align: center;
  color: #1b1464;
  font-size: 38px;
  letter-spacing: -0.04em;
}
.forget-password .box-container {
  max-width: 900px;
}
.forget-password .email-box {
  max-width: 500px;
  margin: 0 auto 50px auto;
}
.forget-password .bgForgot {
  background-color: rgba(44, 44, 44, 0.5);
  border-radius: 5px;
  font-family: "Kanit" !important;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #1b1464 !important;
  padding: 60px;
}

@media (min-width: 471px) and (max-width: 650px) {
  .forget-password .sub-title {
    font-size: 20px;
  }
  .forget-password .bgForgot {
    padding: 30px;
  }
}

@media (max-width: 470px) {
  .forget-password .sub-title {
    font-size: 16px;
  }
  .forget-password .bgForgot {
    padding: 10px;
  }
}
