.notification-report .grid-story6 {
  grid-template-columns: 19% 19% 19% 19% 9.5% 9.5%;
  gap: 1%;
}

.notification-report .grid-story6 .btn {
  width: 100%;
}

.notification-report + .report-table .xls-file {
  cursor: pointer;
}
