.map-boundary-map {
  padding: 0;
  margin: 0;
  height: calc(100vh - 20px);
  width: 100%;
  -webkit-border-radius: 60px;
}
.map-boundary-map.esri-view .esri-view-surface--inset-outline:focus::after {
  display: none;
}

.map-boundary-map .esri-ui-top-right {
  top: 50px;
}
