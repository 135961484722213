.ant-layout.reset-password {
  height: 100vh;
  background: url("/public/images/bg-login.jpg") 0 / cover no-repeat;
  max-width: none;
  margin: 0;
  padding: 0;
  min-height: 746px;
}
.reset-password .bgForgot {
  background-color: rgba(44, 44, 44, 0.5);
  border-radius: 5px;
  font-family: "Kanit" !important;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #1b1464 !important;
  padding: 60px;
}

.reset-password .password-box {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.reset-password .submit-button {
  background-color: #1b1464;
  border-color: #1b1464;
  margin: 20px 0;
}

@media (min-width: 471px) and (max-width: 650px) {
  .reset-password .sub-title {
    font-size: 20px;
  }
  .reset-password .bgForgot {
    margin-top: 100px;
    padding: 30px;
  }
}

@media (max-width: 470px) {
  .reset-password .sub-title {
    font-size: 16px;
  }
  .reset-password .bgForgot {
    margin-top: 100px;
    padding: 10px;
  }
}
