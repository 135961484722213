.event-log-page .date {
  margin-top: 20px;
}
.event-log-page .btn {
  width: auto;
}
/* .event-log-page .form-group {
  width: 80%;
}
.event-log-page .btn-save {
  width: 80%;
} */
