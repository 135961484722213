.notification .table thead tr th {
  text-align: center;
}

.notification .table tbody tr th:first-child {
  text-align: right;
}

.notification .header-topic.sub {
  border: none;
  margin-top: 30px;
  padding: 0;
}

.notification .notification-today {
  padding: 30px 24px;
  padding-top: 0;
  margin-bottom: 30px;
  border-bottom: 1px solid #1b1464;
}

.notification .overall-table {
  margin-top: 30px;
}

.notification .overall-table .box {
  text-align: center;
  margin-right: 2px;
  border: 3px solid grey;
}

.notification .overall-table .box.high {
  border-color: #d65544;
}

.notification .overall-table .box.medium {
  border-color: #f7bd21;
}

.notification .overall-table .box.low {
  border-color: #65ca8e;
}

.notification .latest-noti .row .col {
  display: flex;
  align-items: center;
}

.notification .latest-noti .box {
  width: 15px;
  height: 15px;
}

.notification .latest-noti .box.high {
  background-color: #d65544;
}

.notification .latest-noti .box.medium {
  background-color: #f7bd21;
}

.notification .latest-noti .box.low {
  background-color: #65ca8e;
}

.notification .icon {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 5px;
}

.notification .icon.low {
  background-color: #65ca8e;
}

.notification .icon.medium {
  background-color: #f7bd21;
}

.notification .icon.high {
  background-color: #d65544;
}

.notification .table tbody tr td {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.notification .table tbody tr td:nth-child(3) {
  text-align: left;
  width: 150px;
}

.notification .table tbody tr td:last-child {
  text-align: right;
}

.notification .table tbody tr td:last-child p {
  display: inline-block;
  padding: 8px 10px;
  font-weight: bold;
  margin-right: 20px;
}

.notification .table tbody tr:last-child th:first-child {
  border-radius: 0 0 0 8px;
}

.notification .table tbody tr:last-child td:last-child {
  border-radius: 0 0 8px 0;
}

.notification .search-bar .grid-story6 {
  grid-template-columns: 18% 18% 18% 2% 18% 16%;
  gap: 2%;
}

.notification .search-bar .grid-story6 .form-group {
  display: flex;
  align-items: center;
}

.notification .search-bar .grid-story6 button {
  margin-left: 60px;
  width: auto;
}
.notification .table tbody tr:nth-child(2n) {
  background-color: #ffffff;
  border-color: #ffffff;
}
.notification .search-bar .grid-story6 .download-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  left: 0;
}
@media (max-width: 1050px) {
  .notification .search-bar .grid-story6 {
    grid-template-columns: none;
    gap: 0;
  }
  .notification .search-bar .grid-story6 .form-group {
    margin-bottom: 20px;
  }
  .notification .search-bar .grid-story6 .form-group:nth-child(4) {
    display: none;
  }

  .notification .search-bar .grid-story6 button {
    margin: 0 auto;
    padding: 5px 30px;
  }

  .notification .search-bar .grid-story6 .download-icon {
    position: static;
  }
}
.notification .ant-checkbox-wrapper .ant-checkbox-inner {
  border: 1px solid #262323 !important;
}
.notification .btn-save {
  width: 100%;
}
