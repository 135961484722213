.update-Rrole .permission-row .form-check,
.update-Rrole .permission-row .form-check .ant-checkbox-wrapper {
  padding: 0;
}

.update-Rrole
  .permission-row
  .form-check
  .ant-checkbox-wrapper
  .ant-checkbox-inner {
  border: 1px solid #262323 !important;
}

.update-Rrole .status-row .form-check .form-check-input {
  width: 20px;
  height: 20px;
}

.update-Rrole .status-row .form-check .form-check-label {
  position: relative;
  top: 3px;
}
