.device #search-device-list {
  margin-top: 15px;
  font-size: 20px;
  width: 120px;
}
.device .DevEui .camera {
  right: -26px;
}
.device .search-bar .grid-story2 {
  grid-gap: 20%;
  grid-template-columns: 40% 33%;
}

.device .accordion-item {
  border-radius: 0;
}
.device .searchdevice {
  margin-top: 20px;
}
.device .accordion-button {
  border: 1px solid black;
  padding: 16px;
  font-size: 14px;
  border-radius: 0 !important;
}
.device .accordion-button h1,
.device .accordion-button p,
.device .accordion-body h1,
.device .accordion-body p {
  font-size: 14px;
}

.device .accordion-button::after {
  position: relative;
  left: -43px;
}

.device .accordion-item .accordion-button.collapsed {
  border: none;
}
.device .device-detail .edit {
  right: 1%;
}
.device .grid-story3 {
  grid-template-columns: repeat(4, 1fr);
}
.device .form-group {
  text-align: left;
  margin-bottom: -25px;
}
.device .button-box {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}
.device .button-box .btn {
  margin: 0;
  margin-right: 10px;
  width: 120px;
  font-size: 15px;
  font-weight: bold;
  height: 40px;
  font-family: "Kanit";
}
.device .card-body .icon {
  margin-right: 17px;
  width: 60px;
}

.device .accordion-collapse.collapse.show {
  height: 80px;
  margin: -16px;
  margin-left: -20px;
}

.device .report-table .command .btn-save {
  margin: 0 auto;
  width: 80px;
}
@media (max-width: 1580px) {
  .device .status-tpe .card-body {
    text-align: center;
  }
  .device .status-tpe .card-body .icon {
    display: block;
    margin: 0 auto;
  }
}
.device .card-body .find-device-header {
  font-size: 16px;
}

@media (max-width: 1150px) {
  .device .status-tpe .card-body {
    margin-top: 0;
    padding: 10px;
  }

  .device .status-tpe .card-body .icon {
    width: 40px;
  }
  .device .status-tpe {
    grid-gap: 5px;
  }
}

@media (max-width: 900px) {
  .device .status-tpe .card-body {
    padding: 0;
  }
  .device .status-tpe {
    grid-template-columns: none;
  }
}
@media (max-width: 700px) {
  .device .search-bar .grid-story2 {
    grid-template-columns: none;
    grid-gap: 0;
  }
  .device .DevEui .camera {
    right: 8px;
  }
}
@media (max-width: 1000px) {
  .device .accordion-collapse.collapse.show {
    height: 100px;
  }
}
@media (max-width: 950px) {
  .device .accordion-collapse.collapse.show {
    height: 140px;
  }
  .device .device-detail .accordion-body {
    height: 140px;
  }
  .device .device-detail .edit {
    right: 2%;
  }
}
@media (max-width: 580px) {
  .device .accordion-collapse.collapse.show {
    height: 200px;
  }
  .device .device-detail .accordion-body {
    height: 200px;
  }
  .device .device-detail .edit {
    right: 3%;
  }
}
@media (max-width: 380px) {
  .device .accordion-collapse.collapse.show {
    height: 220px;
  }
  .device .device-detail .accordion-body {
    height: 220px;
  }
  .device .device-detail .edit {
    right: 4%;
  }
}
