header.header-page {
  padding: 0;
  background-color: #428bca;
  height: 60px;
}

header.header-page .header-menu {
  display: flex;
}

header.header-page .card-body .sub-menu a {
  color: rgb(112, 112, 122);
}

header.header-page .card-body .sub-menu .logout {
  font-size: 16px;
  line-height: 24px;
}

header.header-page .right-menu {
  display: flex;
  height: 60px;
  margin-right: 10px;
}

header.header-page .right-menu .user-menu {
  padding: 0;
}

header.header-page .right-menu .user-menu .username .details {
  font-size: 14px;
  text-align: left;
}

header.header-page .right-menu .user-menu .noti .noti-num {
  position: absolute;
  left: 10px;
  top: -9px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: red;
  color: white;
  font-size: 9px;
}

header.header-page #main-navigation li {
  font-size: 16px;
}

header.header-page #main-navigation .main-menu {
  justify-content: flex-start;
}

header.header-page #main-navigation .main-menu.is-space-between {
  justify-content: space-between;
}

header.header-page #main-navigation .humberger {
  height: 60px;
  display: flex;
  margin: 0;
  align-items: center;
}

header.header-page #main-navigation .humberger img {
  width: 34px;
  height: 25px;
  margin-left: 10px;
  margin-right: 10px;
  display: block;
}

header.header-page #main-navigation .humberger img.close-menu {
  width: 25px;
  height: 25px;
}

header.header-page .is-notification {
  margin-right: 10px;
}
header.header-page .is-notification.off {
  margin-right: 42px;
}

@media (max-width: 1200px) {
  header.header-page .right-menu {
    position: absolute;
    right: 0;
    margin-right: 0;
  }
  header.header-page #main-navigation .user-menu {
    position: static;
    margin-right: 20px;
  }
  header.header-page #main-navigation .humberger {
    position: static;
  }
}

@media (max-width: 580px) {
  header.header-page .user-menu li {
    margin-right: 10px;
  }
}
