// There are some major variables below,
// all less variables could be found in
// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less

@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@200&family=Poppins&display=swap");

// font
@font-family: "Kanit", -apple-system, BlinkMacSystemFont, "Poppins", Roboto,
  "Helvetica Neue", Arial, "Noto Sans", sans-seri;
// base variables
@border-radius-base: 6px;

// colors
@primary-color: #1b1464;
@error-color: #fa541c;
@text-color: #1b1464;

// sider
@layout-sider-background: #626262;
@menu-bg: transparent;
@menu-item-color: #fff;
@menu-highlight-color: #fff;
@menu-inline-submenu-bg: transparent;
@menu-item-active-bg: transparent;
@menu-item-active-border-width: 0px;
@layout-trigger-background: rgba(0, 0, 0, 0.5);

//form
@label-color: @text-color;

.bg-login {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  font-family: "Kanit", sans-serif !important;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #1b1464 !important;
  padding: 20px 40px;
}
.bg-forgot {
  background-color: rgba(44, 44, 44, 0.5);
  border-radius: 5px;
  font-family: "Kanit", sans-serif !important;
  text-align: center;
  line-height: 1;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  color: #1b1464 !important;
  padding: 60px 60px;
}
.font-color {
  color: #1b1464 !important;
}
.login-frogot {
  border-bottom: 1px solid #1b1464;
  font-size: 13px;
  color: #1b1464;
  margin-top: 20px;
}

.frogot-home {
  border-bottom: 1px solid #fff;
  font-size: 13px;
  color: #fff;
  margin-top: 20px;
}
.frogot-home:hover {
  border-bottom: 1px solid #1b1464;
  font-size: 13px;
  color: #1b1464;
  margin-top: 20px;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0px;
}

.ant-form-item-label > label {
  font-weight: bold;
}

.ant-page-header {
  border-radius: @border-radius-base;
}
.ant-menu-submenu-popup > .ant-menu {
  background-color: #626262;
}
.ant-menu-tree-arrow {
  position: absolute;
  top: 50%;
  right: 16px;
  width: 10px;
  color: #fff;
  transform: translateY(-50%);
  transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.ant-menu-tree-arrow::before,
.ant-menu-tree-arrow::after {
  position: absolute;
  width: 6px;
  height: 1.5px;
  background-color: currentcolor;
  border-radius: 2px;
  transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
    color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  content: "";
}
.ant-menu-tree-arrow::before {
  transform: rotate(45deg) translateY(-2.5px);
}
.ant-menu-tree-arrow::after {
  transform: rotate(-45deg) translateY(2.5px);
}
.imageContainer1 {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}
.ant-dropdown-menu {
  margin-top: 20px;
  width: 150px;
}
