.map-boundary-filter .flexible-modal-mask,
.map-boundary-filter .flexible-modal-drag-area-left,
.map-boundary-filter .flexible-modal-drag-area-bottom,
.map-boundary-filter .flexible-modal-drag-area-right {
  display: none;
}
.map-boundary-filter {
  position: absolute;
  background-color: white;
  top: 200px;
  right: 10px;
  width: 250px;
  min-height: 350px;
}
.map-boundary-filter .flexible-modal {
  overflow: hidden;
}
.map-boundary-filter .flexible-modal .accordion-body {
  height: calc(100% - 40px);
  width: calc(100% + 10px);
  overflow-y: scroll;
}

.map-boundary-filter .accordion-header {
  padding: 15px;
  color: white;
  z-index: 10;
  background-color: #428bca;
}

.map-boundary-filter ul li {
  display: block;
  margin-bottom: 5px;
}
.map-boundary-filter .close-modal {
  width: 25px !important;
  height: 25px !important;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 5;
  cursor: pointer;
}

.map-boundary-filter ul li .ant-checkbox-inner {
  background-color: #428bca;
  border-color: #428bca;
}
