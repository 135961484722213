.workpermit .grid-story5 {
  grid-template-columns: repeat(6, 1fr);
}
.workpermit .form-control {
  width: 100%;
}
@media (max-width: 580px) {
  .workpermit .form-control {
    width: 100% !important;
  }
}

.workpermit .table-responsive {
  margin-bottom: -10px;
}
