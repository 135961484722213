.find-employee-modal {
  border: none;
}
.find-employee-modal .ant-tabs-nav-wrap {
  padding: 0 15px;
}
.find-employee-modal .form-group {
  padding: 15px;
  position: relative;
}

.find-employee-modal .search-modal {
  background-color: #428bca;
  padding: 10px 15px;
  color: white;
}

.find-employee-modal .notice {
  position: absolute;
  top: 58px;
}
.find-employee-modal .flexible-modal-mask {
  background-color: transparent;
}
