.map-boundary-basemap .ant-modal-content {
  padding: 0;
}

.map-boundary-basemap.flexible-modal {
  border: none;
}
.map-boundary-basemap .card-body.active {
  background-color: #428bca;
  color: white;
}
.map-boundary-basemap .accordion-header {
  padding: 15px;
  color: white;
  z-index: 10;
  background-color: #428bca;
  border-radius: 5px 5px 0 0;
}
.map-boundary-basemap .type-basemap {
  margin-top: 12px;
  margin-left: 0px;
}
.map-boundary-basemap .header {
  border-radius: 5px;
}
.map-boundary-basemap {
  border-radius: 5px;
}
.map-boundary-basemap .card-body {
  cursor: pointer;
  margin: 0;
  box-shadow: none;
  border-radius: 0;
  padding: 26px 10px !important;
  border-bottom: 1px solid black;
}
