.button-group .search {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 90px;
}
.button-group .esri-button.active {
  background-color: #ff6000;
  border: 1px solid #ff6000;
  color: #fff;
}

.button-group .person {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 123px;
}

.button-group .boundary {
  width: 33px;
  height: 33px;
  position: absolute;
  right: 18px;
  top: 90px;
}

.button-group .togle {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 210px;
}
.button-group .searchBoudary {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 250px;
}
.map-monitoring .map-point .resetMap {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 290px;
}
.map-monitoring .map-point .ship-ptt {
  width: 33px;
  height: 33px;
  position: absolute;
  left: 18px;
  top: 400px;
}
